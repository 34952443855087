import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Section from "../components/section";
import MainHeading from "../components/main-heading";
import ImageText from "../components/image-text";
import TextImage from "../components/text-image";
import Li from "../components/li";
import UiLink from "../components/ui-link";
import UiLinkContainer from "../components/ui-link-container";

import mainImg from "../images/undraw_add_document_0hek.svg";
import postImg from "../images/post.png";

import tickIcon from "../images/icons/tick.svg";
import fixIcon from "../images/icons/fix.svg";
import buyIcon from "../images/icons/buy.svg";
import bsodIcon from "../images/icons/bsod.svg";

function ZalozeniDotazuPage() {
    return (
      <Layout>
        <SEO title="Jak přidat nový dotaz"/>
        <Section>
            <MainHeading heading="Jak přidat nový dotaz" section="Rozcestník">
            </MainHeading>
            <ImageText image={mainImg}>
              <p className="text-xl sm:text-3xl text-gray-600 font-bold text-center mx-4 my-8 lg:mx-16">Správně položený dotaz je základem řešení jakéhokoliv problému.</p>
            </ImageText>
            <div className="w-full lg:w-4/6">
              <ol className="text-lg sm:text-xl">
                <Li>
                  <p>Přečtěte si, <a className="font-semibold hover:underline" href="https://www.facebook.com/groups/PCporadna.net/about" rel="noopener noreferrer">čím se v poradně zabýváme</a>.</p>
                  <ul className="text-base">
                    <li className="my-2">Rozhodněte se, zda váš dotaz do poradny patří nebo ne. Pokud ano, pokračujte 🙂.</li>
                  </ul>
                </Li>
                <Li>
                  <p>Otevřete záložku <a className="font-semibold hover:underline" href="https://www.facebook.com/groups/PCporadna.net/" rel="noopener noreferrer">Diskuze</a> a klepněte do pole pro psaní příspěvku.</p>
                  <ul className="text-base">
                    <li className="my-2">Teď už víte, že PC poradna není jen o PC. Nepište „offtopic“, ani „vím, že to sem nepatří“.</li>
                    <li className="my-2">Obyčejné „Ahoj 👋“ docela potěší. A tykání je v poradně normální.</li>
                    <li className="my-2">Není od věci na začátku shrnout téma příspěvku - třeba „Utopený mobil“.</li>
                    <li className="my-2">Různé poutače (barevná pozadí, nesouvisející obrázky, CAPS LOCK nebo psaní velkým písmem) přiláká spíše více nekompetentních lidí než těch, kteří budou schopni poradit.</li>
                  </ul>
                </Li>
              </ol>
            </div>

            <TextImage image={postImg}>
              <ol className="text-lg sm:text-xl w-full" start="3">
                <Li>
                  <p>Zeptejte se konkrétně.</p>
                  <ul className="text-base">
                  <li className="my-2">Váš příspěvek by neměl nutit k dalším otázkám. Pište k věci a uveďte podrobnosti o problému. Nezajímá nás že se počítač nezapne po prokalené noci, je však důležité zmínit že jste vyměnili procesor.</li>
                  <li className="my-2">Vžijte se do role člověka, který o vašem problému nic neví a musí nějak pochopit, co se mu snažíte říct.</li>
                  </ul>
                </Li>
                <Li>
                  <p>Naformátujte text tak, aby se ostatním lépe četl.</p>
                  <ul className="text-base">
                    <li className="my-2">Stručný nadpis shrnující obsah příspěvku můžete napsat velkým písmem.</li>
                    <li className="my-2">Místo jednoho nepřehledného bloku textu vytvořte několik oddělených odstavců podle jejich obsahu.</li>
                    <li className="my-2">Vyjmenováváte-li několik položek, použijte seznam s odrážkami.</li>
                    <li className="my-2">Důležitá slova zvýrazněte tučně, nebo kurzívou. Používejte však tyto nástroje s mírou, jinak se text stane opět nepřehledným.</li>
                    <li className="my-2">Text se nám také bude mnohem lépe číst, když jej napíšete celými větami, s diakritikou, gramaticky správně a budete používat interpunkci a mezery tam kde mají být. Nehrajeme si na češtináře, ale taky nechceme trávit naše mládí snahou louskat různé hieroglyfy a šifry.</li>
                  </ul>
                </Li>
                <Li>
                  <p>Před odesláním si znovu přečtěte celý dotaz. Zkontrolujte a opravte chyby.</p>
                </Li>
              </ol>
            </TextImage>
            <p className="text-lg sm:text-xl text-center">Po odeslání se příspěvek zařadí do fronty ke kontrole.</p>
            <UiLinkContainer>
                <UiLink 
                headerText="Podívejte se, jak příspěvky schvalujeme."
                link="/kontrola-prispevku/"
                image={tickIcon}/>
            </UiLinkContainer>
            <UiLinkContainer>
                <UiLink 
                  headerText="Jak řešit technický problém"
                  link="/technicky-problem/"
                  image={fixIcon}/>
                <span className="flex-grow ml-16 mr-4 border-b border-gray-200"></span>
                <UiLink 
                  headerText="Co s modrou obrazovkou smrti (BSOD)"
                  link="/bsod/"
                  image={bsodIcon}/>
                <span className="flex-grow ml-16 mr-4 border-b border-gray-200"></span>
                <UiLink 
                  headerText="Jak se zeptat na doporučení hardware"
                  descriptionText="Nákup nebo upgrade počítače, notebooku, mobilního telefonu"
                  link="/doporuceni-hardware/"
                  image={buyIcon}/>
            </UiLinkContainer>
            
        </Section>

      </Layout>

      );
    }
    
export default ZalozeniDotazuPage;
